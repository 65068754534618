import React, { useContext, useEffect } from 'react';
import { graphql, Link } from 'gatsby';
import { PATH } from '../helper/config';
import { Helmet } from 'react-helmet';

import '../css/news_detail.css';
import { AppContext } from '../context/context';
import Util from './utils/util';
import LazyImage from '../components/utils/lazy-image';

const NewsDetail: React.FunctionComponent<any> = ({ data, pageContext }) => {
  const localData = data.wpGqlNewsItem;
  const context = useContext(AppContext);
  let localePath: string = '';
  if (context.locale === 'en_US') localePath = '/en';

  useEffect(() => {
    if (pageContext.locale === 'ja') {
      if (localData.translated.length) {
        context.setLanguage(
          {
            ja: `${PATH.NEWS_DETAIL}${localData.databaseId}`,
            en: `/en${PATH.NEWS_DETAIL}${localData.translated[0].databaseId}`
          },
          pageContext.locale
        );
      } else {
        context.setLanguage(
          {
            ja: `${PATH.NEWS_DETAIL}${localData.databaseId}`,
            en: `/en${PATH.NEWS}`
          },
          pageContext.locale
        );
      }
    } else {
      if (localData.translated.length) {
        context.setLanguage(
          {
            ja: `${PATH.NEWS_DETAIL}${localData.translated[0].databaseId}`,
            en: `/en${PATH.NEWS_DETAIL}${localData.databaseId}`
          },
          pageContext.locale
        );
      } else {
        context.setLanguage(
          {
            ja: `${PATH.NEWS}`,
            en: `/en${PATH.NEWS_DETAIL}${localData.databaseId}`
          },
          pageContext.locale
        );
      }
    }
  }, []);

  useEffect(() => {
    let metaTag;
    metaTag = document.querySelector(`meta[name="Description"]`);
    if (metaTag) metaTag.remove();

    metaTag = document.querySelector(`meta[name="og:description"]`);
    if (metaTag) metaTag.remove();

    metaTag = document.querySelector(`meta[name="twitter:description"]`);
    if (metaTag) metaTag.remove();
  }, []);

  return (
    <article className="news__detail util__page">
      <Helmet>
        <title>{data.wpGqlNewsItem.title} | POST-FAKE</title>
        <meta name="Description" content="" />
        <meta property="og:description" content="" />
        <meta property="og:title" content={`${data.wpGqlNewsItem.title} | POST-FAKE`} />
        <meta property="og:url" content={`https://postfake.com${context.localePath}${PATH.NEWS_DETAIL}${data.wpGqlNewsItem.databaseId}`} />
        {data.wpGqlNewsItem &&
          data.wpGqlNewsItem.gqlNewsAcf &&
          data.wpGqlNewsItem.gqlNewsAcf.image &&
          data.wpGqlNewsItem.gqlNewsAcf.image.mediaItemUrl && (
            <meta property="og:image" content={data.wpGqlNewsItem.gqlNewsAcf.image.mediaItemUrl} />
          )}
        <meta name="twitter:site" content={`${data.wpGqlNewsItem.title} | POST-FAKE`} />
        <meta name="twitter:title" content={`${data.wpGqlNewsItem.title} | POST-FAKE`} />
        <meta name="twitter:description" content="" />
      </Helmet>
      <p className="news__detail-meta ff-mono">
        <span>{Util.GET_DATE(localData.date).ja}</span>
        <span> / </span>
        <span>
          {localData.gqlNewsCategories.nodes &&
            localData.gqlNewsCategories.nodes.map((elem: any, i: number) => {
              return (
                <span className="news__detail__category" key={`news__detail-category-${i}-${elem.name}`}>
                  {elem.name}
                </span>
              );
            })}
        </span>
      </p>
      <div className="news__detail__row">
        <figure className="news__detail__main-image">
          {localData.gqlNewsAcf.image && <LazyImage data={localData.gqlNewsAcf.image} />}
        </figure>
        <div className="news__detail__column">
          <h1 className="news__detail__caption">{localData.title}</h1>
          <div className="news__detail__body" dangerouslySetInnerHTML={{ __html: localData.gqlNewsAcf.content }}></div>
        </div>
      </div>
      <div className="util__page-mover-all">
        <Link to={`${localePath}${PATH.NEWS}`} className="link__text ff-univ-bold">
          ● BACK TO ALL
        </Link>
      </div>
    </article>
  );
};
export default NewsDetail;

export const query = graphql`
  query newsDetailQuery($databaseId: Int!) {
    wpGqlNewsItem(databaseId: { eq: $databaseId }) {
      databaseId
      translated {
        databaseId
      }
      title
      date
      gqlNewsCategories {
        nodes {
          slug
          name
        }
      }
      gqlNewsAcf {
        content
        image {
          mediaItemUrl
          mediaDetails {
            height
            width
          }
        }
      }
    }
  }
`;
